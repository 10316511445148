$(document).ready(function() {
  var $iconLinks = $('#main-navigation').find('span[class*="fa-"], a[class*="fa-"]');
  var regexIcon = /(fa-\S+)/g;

  $iconLinks.each(function(index, el) {
    var match = $(el).attr('class').match(regexIcon);

    if (match.length === 1) {
      var icon = match[0];

      $(el).html('<i class="fa ' + icon + '"></i>');
    }
  });
});
